import Link from 'next/link'
import Image from 'next/image'
import QRCode from 'qrcode.react'

const footerLinkData = [
  {
    imgPath: '/static/images/icons/phone-yellow.svg',
    href: 'tel:77774080',
    text: '7777-4080',
  },
  {
    imgPath: '/static/images/icons/mail-yellow.svg',
    href: 'mailto:contact@bananamall.mn',
    text: 'contact@bananamall.mn',
  },
  {
    imgPath: '/static/images/icons/pin-yellow.svg',
    href: 'https://www.google.com/maps/place/Altan+Joloo+Tower/@47.911977,106.9059343,305m/data=!3m1!1e3!4m14!1m7!3m6!1s0x5d9693958471bd03:0x15fc17b0bc9e99cd!2sNew+Horizons+Tower!8m2!3d47.9154084!4d106.9212302!16s%2Fg%2F11syv21_cg!3m5!1s0x5d96933fcba07637:0xf746822e44ee5b35!8m2!3d47.9126275!4d106.9061602!16s%2Fg%2F11nxp1hphp?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D',
    text: 'Улаанбаатар, Сүхбаатар дүүрэг, 3-р хороо, Сөүлийн гудамж, “Алтан Жолоо Тауэр” оффис, 10 давхар',
  },
]

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-sm-6 align-self-center order-lg-1 order-sm-3 order-4">
            <div className="footer-logo-wrap text-center">
              <Image
                src="/static/images/footer_logo_v2.webp"
                alt="Banana logo footer"
                layout={'fixed'}
                width={296}
                height={84}
                quality={75}
              />
              <p>© 2023 Digital Mall LLC</p>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 order-lg-2 order-sm-4 order-3">
            <ul className="app-download list-unstyled d-flex justify-content-center align-items-center">
              <li className="d-flex flex-column">
                <Image
                  src="/qr.svg"
                  alt="Play store QR code"
                  loading="lazy"
                  width={200}
                  height={200}
                  quality={75}
                />
                <a
                  href="https://apps.apple.com/ca/app/bananamall/id1562615154"
                  target="_blank"
                >
                  <Image
                    src="/static/images/qr/apple-en.png"
                    alt="App store logo"
                    loading="lazy"
                    width={80}
                    height={30}
                    quality={75}
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.bananamall"
                  target="_blank"
                >
                  <Image
                    src="/static/images/qr/google-en.png"
                    alt="Play store logo"
                    loading="lazy"
                    width={80}
                    height={30}
                    quality={75}
                  />
                </a>
              </li>
            </ul>
          </div>

          <div className="offset-lg-0 col-lg-2 offset-sm-1 col-sm-6 order-lg-3 order-2">
            <ul className="footer-link list-unstyled">
              <li className="title">Холбоосууд</li>
              <li>
                <Link href={'/delivery'}>
                  <a>
                    <span>Хүргэлтийн мэдээлэл</span>
                  </a>
                </Link>
              </li>
              <li>
                <Link href={'/faq'}>
                  <a>
                    <span>Түгээмэл асуултууд</span>
                  </a>
                </Link>
              </li>
              <li>
                <Link href={'/service'}>
                  <a>
                    <span>Үйлчилгээний нөхцөл</span>
                  </a>
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-sm-5 order-lg-4 order-1">
            <div className="footer-link text-start">
              <p className="title">Холбоо барих</p>
              {footerLinkData.map((data, index) => {
                return (
                  <a
                    href={data.href}
                    className="d-flex mb-2"
                    key={index}
                    target="_blank"
                    rel="noopener"
                  >
                    <img
                      src={data.imgPath}
                      alt={data.value}
                      height={24}
                      weight={24}
                      quality={75}
                    />
                    <span>{data.text}</span>
                  </a>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
