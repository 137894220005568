import BaseLayout from 'layouts/BaseLayout'
import BasePage from 'layouts/BasePage'
// import StoriesApi from 'lib/api/stories'
import ProductsApi from 'lib/api/product'
import CategoriesApi from 'lib/api/category'
import BannersApi from 'lib/api/banners'
import CollectionsApi from 'lib/api/collections'
import Home from 'pages/home'

export const revalidate = 60

export default function Index({
  // stories,
  fourBanner,
  brandBannerTech,
  brandBannerBeau,
  travelBanners,
  longBanners,
  lendPromotionBanners,
  blackFridayBackgrounds,
  showroomTaxonBanners,
  mainBanner,
  category,
  categories,
  homeCollections,
  specialSaleProducts,
}) {
  return (
    <BaseLayout categories={categories}>
      <BasePage>
        <Home
          // stories={stories}
          fourBanner={fourBanner}
          mainBanner={mainBanner}
          longBanners={longBanners}
          travelBanners={travelBanners}
          brandBannerTech={brandBannerTech}
          brandBannerBeau={brandBannerBeau}
          showroomTaxonBanners={showroomTaxonBanners}
          lendPromotionBanners={lendPromotionBanners}
          blackFridayBackgrounds={blackFridayBackgrounds}
          specialSaleProducts={specialSaleProducts}
          category={category}
          homeCollections={homeCollections}
          // salesBanners={salesBanners}
        />
      </BasePage>
    </BaseLayout>
  )
}

export async function getStaticProps() {
  const [
    mainBannerJson,
    travelBannersJson,
    longBannersJson,
    fourBannerJson,
    brandBannerTechJson,
    brandBannerBeauJson,
    lendPromotionBannersJson,
    blackFridayBackgroundsJSON,
    taxonBannerShowroom,
    categoriesJson,
    homeCollectionsJson,
    specialSaleProductsJson,
    lendPromotionCategoryJson,
  ] = await Promise.all([
    new BannersApi().getMainBanner(),
    new BannersApi().getTravelBanners(),
    new BannersApi().getLongBanner(),
    new BannersApi().getFourBanner(),
    new BannersApi().getBrandBannerTech(),
    new BannersApi().getBrandBannerBeau(),
    new BannersApi().getLendPromotionBanners(),
    new BannersApi().getBlackFridayBackgrounds(),
    new BannersApi().getSwowroomTaxonBanners(),
    new CategoriesApi().getCategories(),
    new CollectionsApi().getHomeCollections(),
    new ProductsApi().getSaleProducts(1, 15, '&sale=desc'),
    new CategoriesApi().getCategoryDetail('Ursdag-banner'),
    new BannersApi().getJustmove(),
  ])

  // Assigning fetched data to variables

  const category = JSON.parse(JSON.stringify(lendPromotionCategoryJson.data))
  const fourBanner = fourBannerJson.data['hydra:member']
  const mainBanner = mainBannerJson.data['hydra:member']
  const longBanners = longBannersJson.data['hydra:member']
  const travelBanners = travelBannersJson.data['hydra:member']
  const brandBannerTech = brandBannerTechJson.data['hydra:member']
  const brandBannerBeau = brandBannerBeauJson.data['hydra:member']
  const showroomTaxonBanners = taxonBannerShowroom.data['hydra:member']
  const lendPromotionBanners = lendPromotionBannersJson.data['hydra:member']
  const blackFridayBackgrounds = blackFridayBackgroundsJSON.data['hydra:member']
  const categories = categoriesJson.data['hydra:member']
  const homeCollections = homeCollectionsJson.data
  const specialSaleProducts = specialSaleProductsJson.data.data
  return {
    props: {
      fourBanner,
      travelBanners,
      mainBanner,
      longBanners,
      brandBannerTech,
      brandBannerBeau,
      lendPromotionBanners,
      showroomTaxonBanners,
      blackFridayBackgrounds,
      category,
      categories,
      homeCollections,
      specialSaleProducts,
    },
    revalidate: 600,
  }
}
